//
//
//
//
//
//
//
//
//

import IndentedContent from './IndentedContent.vue';

export default {
    name: 'SectionHeadline',
    components: {IndentedContent},
    props: ['headline', 'indent', 'animation'],
};
