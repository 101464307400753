//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Swiper, {Navigation, Pagination} from 'swiper';

Swiper.use([Navigation, Pagination]);

import SectionHeadline from '../Partials/SectionHeadline.vue';
import ImageCopyright from '../Partials/ImageCopyright.vue';
import TextBlock from '../Partials/TextBlock.vue';
import AudioPlayer from '../Partials/AudioPlayer.vue';

export default {
    name: 'TwoImageTeaser',
    components: {TextBlock, ImageCopyright, SectionHeadline, AudioPlayer},
    props: ['data'],
    data() {
        return {
            mediaPath: this.$config.pimcoreUrl,
        };
    },
    computed: {
        onlyHeadline() {
            //BETTE-636 this component is also used as a heading module
            if (this.data?.text || this.imageOne.baseImg || this.imageTwo.baseImg) {
                return false;
            }
            return true;
        },
        imageOne() {
            return this.$mapImageData(this.data?.image_one?.image);
        },
        imageTwo() {
            return this.$mapImageData(this.data?.image_two?.image);
        },
    },
    mounted() {
        var twoImageTeaserSwiper = new Swiper('.two-image-teaser__slider-container--slide', {
            direction: 'horizontal',
            loop: false,
            a11y: true,
            spaceBetween: 5,
            slidesPerView: 1.1,
            speed: 750,
        });
    },
};
